import React from 'react'
import {ConsentForm} from "./component/ConsentForm"
import {ConsentFormView} from "./component/ConsentFormView"
import { render } from 'react-dom'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import {AssignConsent} from "./component/AssignConsent";
import {IBDConsentForm} from "./component/IBDConsentForm";
import { createTheme } from '@material-ui/core/styles';
import {ThemeProvider} from "@material-ui/core";
import {IBDSingleDownload} from "./component/IBDSingleDownload";
import IBDUploadPresentation from "./component/IBDUploadPresentation";
import './style.css'

const theme = createTheme({
    typography: {
        fontFamily: 'Verdana-Regular, sans-serif!important',
    },
});
document.title = "Lämna ditt samtycke";
if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
        value: function assign(target, varArgs) { // .length of function is 2
            'use strict';
            if (target === null || target === undefined) {
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource !== null && nextSource !== undefined) {
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true
    });
}

render(
    <Router>
        <Switch>
            <Route path="/ibd/upload/:userToken">
                <ThemeProvider theme={theme}>
                    <IBDUploadPresentation/>
                </ThemeProvider>
            </Route>
            <Route path="/ibd/upload/">
                <ThemeProvider theme={theme}>
                    <IBDUploadPresentation/>
                </ThemeProvider>
            </Route>
            <Route path="/ibd/download/:receiverId">
                <ThemeProvider theme={theme}>
                    <IBDSingleDownload/>
                </ThemeProvider>
            </Route>
            <Route path="/ibd">
                <ThemeProvider theme={theme}>
                    <IBDConsentForm/>
                </ThemeProvider>
            </Route>
            <Route path="/view/:consentObject">
                <ConsentFormView/>
            </Route>
            <Route path="/assign/:consentId/:userId">
                <AssignConsent/>
            </Route>
            <Route path="/:uuid">
                <ConsentForm/>
            </Route>
            <Route path="/">
                <ConsentForm/>
            </Route>
        </Switch>
    </Router>,
    document.getElementById('root')
)
