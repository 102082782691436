import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Link,
    List,
    ListItem,
    ListItemText
} from "@material-ui/core";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {TranslatedText, translatedTexts} from "../lang";

export const PrivacyPolicySweden = () => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Link href="#" onClick={handleClickOpen} style={{color: 'rgb(1, 172, 230)', fontWeight: "bold"}}>
                integritetspolicy
            </Link>
        <Dialog open={open} onClose={handleClose} fullScreen fullWidth maxWidth="md">
            <DialogTitle >

                <Typography variant={'h6'} style={{ color: '#db1e24', fontWeight: 'bold' }}>
                    Integritetspolicy för hälso- och sjukvårdspersonal - Tillotts Pharma AB (2020-04-16)
                </Typography>
            </DialogTitle>
            <DialogContent dividers style={{  overflowY: "auto" }}>

                <Typography variant="h5" gutterBottom>
                    1. Bakgrund
                </Typography>
                <Typography paragraph>
                    Denna integritetspolicy (”policyn”) har upprättats för att Tillotts Pharma AG och dess helägda dotterbolag[1] (”Tillotts”, ”Tillotts Group” eller ”vi”) vill förse dig med information om hur vi behandlar dina personuppgifter och få ditt samtycke för specifika syften enligt nedanstående information.
                </Typography>
                <Typography variant="h5" gutterBottom>
                   2  Vilken typ av personuppgifter vi samlar in och varför
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary=
                                { <Typography variant="h6" gutterBottom>
                                    a) Kundrelationshantering
                                </Typography>}
                            secondary={
                            <>
                                <Typography paragraph color="textPrimary">
                                    Vi upprätthåller ett kundrelationshanteringssystem där vi kan komma att lagra personuppgifter om dig:
                                </Typography>
                                <Typography paragraph color="textPrimary">
                                    ”Kontaktinformation”: Denna kategori av personuppgifter innehåller t.ex. ditt namn, din adress, ditt telefon-/fax-/mobilnummer, din e-postadress eller annan online baserad kontaktinformation. Vi får kontaktinformation genom personlig kontakt med dig eller från kommersiella adressförsäljningsföretag samt från offentliga källor, t.ex. webbplatser. Vi använder din kontaktinformation för att marknadsföra för våra produkter och tjänster eller leverera brådskande medicinsk information gällande våra produkter.
                                </Typography>
                                <Typography paragraph color="textPrimary">
                                    ”Information om dina intressen”: För att vi ska kunna förbättra din upplevelse med våra produkter och tjänster kontinuerligt, analyserar vi vår personliga kontakt med dig, t.ex. när vi visar surfplattebaserat material. I detta syfte dokumenterar vi vilka ämnen som har visats för dig, under hur lång tid och i vilken ordning samt din reaktion på enskilda ämnen. Vi kan även komma att dokumentera uppgifter om din läkarpraktik, däribland antal patienter, patientkategorier, deltagande i kliniska studier och utbildningsaktiviteter, såväl som din förskrivning av läkemedel samt uppgifter om din yrkeserfarenhet eller yrkesverksamhet som samlas in från offentliga källor.
                                </Typography>
                            </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary=
                                {
                            <Typography variant="h6" gutterBottom>
                                    b) Marknadsundersökningar
                                </Typography>}
                            secondary={
                            <>
                                <Typography paragraph color="textPrimary">
                                    Vi arbetar tillsammans med helt oberoende marknadsundersöknings-företag, som på vårt uppdrag utför marknadsundersökningar globalt, med fokus på våra vetenskapliga intressen och produkter. Vi kan komma att dela din kontaktinformation med dessa marknadsundersökningsföretag för att utföra marknadsundersökningar som är specifika för våra kunder.
                                </Typography>
                            </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary=
                                {
                                <Typography variant="h6" gutterBottom>
                                    c) Leverera elektroniskt material med produktinformation/medicinsk information.
                                </Typography> }
                            secondary={
                                <>
                                    <Typography paragraph color="textPrimary">
                                        Vi kan komma att använda din kontaktinformation för att kommunicera med dig genom telefonsamtal, direktpost, e-post eller annan elektronisk kommunikation (t.ex. fax, chattar på webbplatser, SMS, meddelanden via meddelandetjänster eller detailing på distans däribland kundtjänst på begäran) för att kunna leverera produktinformation/medicinsk information som innehåller information om tjänster, produkter eller evenemang relaterad till ditt medicinska intresse. Produktinformation/medicinsk information som skickas med e-post eller annat elektroniskt kommunikationsmaterial (”elektroniskt material med reklam/medicinsk information”) såväl som genom telefonsamtal förutsätter dock att du ger oss ditt samtycke.
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary=
                                {                <Typography variant="h6" gutterBottom>
                                    d) Analyser av din användning av vårt elektroniska material med produktinformation/medicinsk information
                                </Typography> }
                            secondary={
                                <>
                                    <Typography paragraph color="textPrimary">
                                        För att kunna anpassa vårt elektroniska material med produktinformation/ medicinsk information så att det uppfyller dina behov och önskemål, och under förutsättning att du ger oss ditt samtycke, kan vi komma att analysera din användning av vårt elektroniska material med produktinformation/medicinsk information, exempelvis huruvida du har öppnat materialet och hur du använde vårt elektroniska material med produktinformation/medicinsk information (t.ex. vilka länkar du klickade på).
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                </List>
                <Typography variant="h5" gutterBottom>
                    3.  Behandling av uppgifter
                </Typography>
                <Typography paragraph>
                    Uppgifter om dig kan komma att delas med tredje part som samarbetar med Tillotts, såsom tredje part som erbjuder logistiktjänster eller administrativa tjänster. I samtliga sådana fall kommer Tillotts säkerställa att sådan tredje part är skyldig att garantera en lämplig skyddsnivå för personuppgifter som uppfyller aktuella lagar.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    4.  Rättslig grund för insamling och behandling
                </Typography>
                <Typography paragraph>
                    Om inte annat anges någon annanstans i denna policy, samlar vi in och behandlar dina personuppgifter för vissa berättigade företagsintressen som omfattar de syften som anges under avsnittet ”Vilken typ av personuppgifter vi samlar in och varför” ovan.
                </Typography>
                <Typography paragraph>
                    Dessutom är vi enligt lag skyldiga att säkerställa att dina personuppgifter är korrekta och, om nödvändigt, att de hålls uppdaterade.
                </Typography>
                <Typography paragraph>
                    När vi behandlar dina personuppgifter för våra berättigade intressen ser vi till att överväga och balansera eventuell potentiell inverkan det kan ha på dig (både positiv och negativ) och dina rättigheter under tillämpliga dataskyddslagar. Våra berättigade företagsintressen åsidosätter inte automatiskt dina intressen – vi kommer inte att använda dina personuppgifter för aktiviteter där våra intressen åsidosätts på grund av den inverkan det har på dig (såvida vi inte har ditt samtycke eller om det på annat sätt krävs eller är tillåtet enligt lag). Om du har några frågor om behandlingen av dina personuppgifter har du rätt att invända mot behandling som baseras på våra berättigade företagsintressen. Mer information om dina rättigheter hittar du i avsnittet ”Dina rättigheter gällande personuppgiftsskydd” nedan.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    5. Lagring av personuppgifter
                </Typography>
                <Typography paragraph>
                    Vi lagrar dina personuppgifter endast så länge det är nödvändigt för att uppfylla syftet med behandlingen och enligt tillämpliga lagar, författningar eller andra krav. Om vi inte längre har något berättigat företagsbehov att behandla dina personuppgifter kommer vi att radera dem. Lagstadgade lagringsperioder förblir opåverkade.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    6. Överföring av personuppgifter
                </Typography>
                <Typography paragraph>
                    Vi överför dina personuppgifter till tredje part eller ger tredje part tillgång till dina personuppgifter i följande fall:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="a)                                         Vi använder specialiserade tjänsteleverantörer som hjälper oss att erbjuda våra tjänster. Sådana tjänsteleverantörer är noggrant utvalda och övervakas regelbundet av oss. Baserat på respektive uppgiftsbehandlingsavtal kommer de endast att behandla personuppgifter på vår begäran och strikt i enlighet med våra instruktioner."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="b) Vi kan komma att dela din kontaktinformation med helt oberoende marknadsundersökningsföretag enligt ovan beskrivning i avsnitt ”Vilken typ av personuppgifter vi samlar in och varför”."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="c) Vi kan komma att överföra dina ovan angivna personuppgifter inom Tillotts Group beroende på vilket Tillotts-företag som behöver involveras i hanteringen av en tjänst eller i kommunikationen med dig. Alla Tillotts-företag kommer dock endast att direkt skicka motsvarande information genom e-post eller annat elektroniskt kommunikationsmaterial till dig om du ger oss ditt samtycke."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="d) Dina uppgifter kan även delvis komma att överföras och behandlas i länder utanför Europeiska ekonomiska samarbetsområdet (EES) samt Schweiz, där dataskyddsnivån kan vara lägre. I sådana fall kommer vi att säkerställa att en adekvat dataskyddsnivå tillhandahålls för dina uppgifter, t.ex. genom att vi upprättar specifika avtal med respektive uppgiftsimportör."
                        />
                    </ListItem>
                </List>
                <Typography variant="h5" gutterBottom>
                    7. Säkerhet
                </Typography>
                <Typography paragraph>
                    Vi använder lämpliga tekniska och organisatoriska åtgärder för att skydda dina personuppgifter. Åtgärderna vi använder oss av är framtagna för att tillhandahålla en säkerhetsnivå som står i proportion till de risker som finns när det gäller behandling av personuppgifter.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    8. Dina rättigheter gällande personuppgiftsskydd
                </Typography>
                <Typography paragraph style={{fontWeight: 'bold', marginBottom: '0px'}}>
                    Du har följande rättigheter gällande personuppgiftsskydd:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="Du har rätt att begära tillgång till, rättelse, uppdatering och radering av dina personuppgifter."
                        />
                    </ListItem>
                    <ListItem>
                                                <ListItemText
                            primary="Du har rätt att invända mot behandlingen av dina personuppgifter, be oss att begränsa behandlingen av dina personuppgifter eller begära portabilitet av dina personuppgifter."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Du kan även ta tillbaka ditt samtycke när som helst om vi har samlat in och behandlat dina personuppgifter med ditt samtycke. Ett tillbakatagande av ditt samtycke kommer inte att påverka lagligheten för behandlingen som vi har utfört före ditt tillbakatagande. Det kommer heller inte att påverka behandlingen av dina personuppgifter om det finns andra rättsliga grunder än ditt samtycke."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Du har rätt att lämna in klagomål till en dataskyddsmyndighet om vårt samarbete och vår användning av dina personuppgifter."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={      <Typography
                                variant="body1"
                                style={{ wordBreak: "break-word" }}
                                dangerouslySetInnerHTML={{
                                    __html: `Du kan när som helst kontakta Tillotts med en begäran om att utöva dina rättigheter gällande personuppgiftsskydd, utan avgift, till det lokala Tillotts-företagets postadress eller genom e-post till <a href="mailto:nordicinfo@tillotts.com">nordicinfo@tillotts.com</a>.`
                                }}
                            />}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Du kommer att få ett svar på din begäran enligt tillämpliga dataskyddslagar."
                        />
                    </ListItem>
                </List>
                <Typography paragraph>
                    [1] Dotterbolagen omfattar: Tillotts Pharma AB (Sverige), Tillotts Pharma GmbH (Tyskland), Tillotts Pharma UK Ltd., Tillotts Pharma Limited (Irland), Tillotts Pharma Spain S.L.U., Tillotts Pharma France S.A.S., Tillotts Pharma Czech s.r.o.
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Stäng
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}