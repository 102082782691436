import React, { useEffect, useState } from "react";
import {
    Container,
    CssBaseline,
    Paper,
    Typography,
    Button,
    Link,
    CircularProgress,
    Backdrop
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import logo from "../assets/tillotts_logo.svg";
import Box from "@material-ui/core/Box";
import { Alert } from "@material-ui/lab";

const { REACT_APP_ENDPOINT } = process.env;

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    paper: {
        width: "100%",
        maxWidth: 600,
        minWidth: 600,
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(),
            boxShadow: "none",
            minWidth: "auto",
        },
    },
    header: {
        color: "rgb(1, 172, 230)",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    logo: {
        width: "150px",
        marginBottom: theme.spacing(2),
    },
    link: {
        color: "#D51317",
        fontWeight: "bold",
    },
    downloadButton: {
        marginTop: theme.spacing(4),
        backgroundColor: "#D51317",
        borderRadius: "30px",
        fontWeight: 600,
        "&:hover": {
            backgroundColor: "#A10E12",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 9999, // hög prioritet
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

type StatusState = "LOADING" | "OK" | "ERROR" | "USED";

/**
 * Hjälpfunktion: loggar nedladdningshändelser i backend
 */
async function logDownloadEvent(
    action: "IBD_DOWNLOADED" | "IBD_DOWNLOADED_MANUAL" | "IBD_REQUEST_NEW_LINK",
    receiverId: string
) {
    await fetch(`${REACT_APP_ENDPOINT}ibd/consent`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            payload: { uuid: receiverId },
            action,
        }),
    });
}

/**
 * Hjälpfunktion: hämtar fil från angiven URL och triggar nedladdning i webbläsaren
 */
async function downloadFile(url: string, fileName: string) {
    const fileResponse = await fetch(url);
    if (!fileResponse.ok) {
        throw new Error("File download failed");
    }
    const blob = await fileResponse.blob();
    const fileURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = fileURL;
    tempLink.download = fileName;
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
}

export const IBDSingleDownload: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { receiverId } = useParams<{ receiverId: string }>();

    const [status, setStatus] = useState<StatusState>("LOADING");
    const [url, setUrl] = useState<string | null>(null);

    // Tillstånd för att visa/hindra nedladdning
    const [downloaded, setDownloaded] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [showFallbackButton, setShowFallbackButton] = useState<boolean>(false);

    // Tillstånd kring att begära ny länk
    const [isRequestingNewLink, setIsRequestingNewLink] = useState<boolean>(false);
    const [newLinkRequested, setNewLinkRequested] = useState<boolean>(false);

    /**
     * 1. Kolla länkens status i backend
     */
    useEffect(() => {
        const checkLinkStatus = async () => {
            try {
                const response = await fetch(`${REACT_APP_ENDPOINT}/ibd/url/${receiverId}`);
                const result = await response.json();
                console.log("Link status result:", result);

                if (result.url && result.status === "OK") {
                    setUrl(result.url);
                    setStatus("OK");
                } else if (result.status === "USED") {
                    setStatus("USED");
                } else {
                    setStatus("ERROR");
                }
            } catch (error) {
                console.error("Error checking link status:", error);
                setStatus("ERROR");
            }
        };
        checkLinkStatus();
    }, [receiverId]);

    /**
     * 2. Omdirigera efter 5 sekunder om status === ERROR
     */
    useEffect(() => {
        if (status === "ERROR") {
            const timer = setTimeout(() => {
                // history.push(REDIRECT_FORM_URL);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [status, history]);

    /**
     * 3. Om status === "OK", starta automatisk nedladdning
     */
    useEffect(() => {
        if (status === "OK" && !downloaded && !isDownloading) {
            handleAutoDownload();
        }
    }, [status, downloaded, isDownloading]);

    /**
     * 3a. Visa fallback-knapp om nedladdningen inte lyckas inom 15 sekunder
     */
    useEffect(() => {
        if (status === "OK" && !downloaded) {
            const fallbackTimer = setTimeout(() => {
                if (!downloaded) {
                    setShowFallbackButton(true);
                }
            }, 15000);

            return () => clearTimeout(fallbackTimer);
        }
    }, [status, downloaded]);

    /**
     * startAutoDownload - försöker ladda ner filen automatiskt
     */
    const handleAutoDownload = async () => {
        if (!url) return;
        setIsDownloading(true);
        try {
            await logDownloadEvent("IBD_DOWNLOADED", receiverId);
            await downloadFile(url, "IBD utbildningsmaterial_NP-TP-SE-00051.pptx");
            setDownloaded(true);
        } catch (err) {
            console.error("Automatic download error:", err);
            setShowFallbackButton(true);
        } finally {
            setIsDownloading(false);
        }
    };

    /**
     * handleManualDownload - fallback om auto-download inte funkar
     */
    const handleManualDownload = async () => {
        if (!url) return;
        setIsDownloading(true);
        try {
            await logDownloadEvent("IBD_DOWNLOADED_MANUAL", receiverId);
            await downloadFile(url, "IBD utbildningsmaterial_NP-TP-SE-00051.pptx");
            setDownloaded(true);
        } catch (err) {
            console.error("Manual download error:", err);
        } finally {
            setIsDownloading(false);
        }
    };

    /**
     * Begär ny länk - om den redan är använd
     */
    const handleRequestNewLink = async () => {
        setIsRequestingNewLink(true);
        try {
            await logDownloadEvent("IBD_REQUEST_NEW_LINK", receiverId);
            setNewLinkRequested(true);
        } catch (err) {
            console.error("Error requesting new link:", err);
        } finally {
            setIsRequestingNewLink(false);
        }
    };

    /**
     * En funktion för att rendera UI baserat på state
     */
    const renderContentByStatus = () => {
        switch (status) {
            case "LOADING":
                return (
                    <Paper elevation={3} className={classes.paper}>
                        <img src={logo} alt="Tillotts Logo" className={classes.logo} />
                        <Typography variant="h5" className={classes.header}>
                            Kontrollerar nedladdningslänk...
                        </Typography>
                        <CircularProgress />
                    </Paper>
                );

            case "USED":
                return (
                    <Paper elevation={3} className={classes.paper}>
                        <img src={logo} alt="Tillotts Logo" className={classes.logo} />
                        <Typography variant={`${newLinkRequested ? 'h6' : 'h5'}`} className={classes.header}>
                            {newLinkRequested ? "En ny nedladdningslänk har skickats till din e-post!" : "Den här länken är redan använd"}
                        </Typography>

                        {!newLinkRequested && (
                            <>
                                <Typography variant="body2" align="center" style={{ marginTop: 16 }} dangerouslySetInnerHTML={{__html: `
                                Länken är <strong>personlig</strong> och har använts, den går därför inte att nyttja igen.
                                `}}>
                                </Typography>
                                <Typography variant="body2" align="center" >
                                    Klicka på knappen nedan om du vill begära en ny nedladdningslänk.
                                </Typography>
                                <Box >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isRequestingNewLink}
                                        className={classes.downloadButton}
                                        onClick={handleRequestNewLink}
                                    >
                                        {isRequestingNewLink ? "Skickar..." : "Begär ny nedladdningslänk"}
                                    </Button>
                                </Box>
                                <Typography variant="body2" align="center" style={{ marginTop: 36 }} dangerouslySetInnerHTML={{__html: `
                                Om länken har vidarebefordrats till dig måste du registrera dig för att få en  <strong>egen</strong> personlig länk.
                                `}}>
                                </Typography>
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.downloadButton}
                                        onClick={() => history.push("/ibd")}
                                    >
                                        {"Registrera dig här"}
                                    </Button>
                                </Box>
                            </>
                        )}

                        <Box mt={4}>
                            <Typography variant="body2" align="center">
                                Vid frågor, vänligen kontakta{" "}
                                <Link href="mailto:nordicinfo@tillotts.com" className={classes.link}>
                                    nordicinfo@tillotts.com
                                </Link>
                            </Typography>
                        </Box>
                    </Paper>
                );

            case "ERROR":
                return (
                    <Paper elevation={3} className={classes.paper}>
                        <img src={logo} alt="Tillotts Logo" className={classes.logo} />
                        <Typography variant="h5" className={classes.header}>
                            Länken är inte giltig
                        </Typography>
                        <Typography variant="body1" style={{ textAlign: "center", marginTop: 16 }}>
                            Den här länken är ogiltig. Du kommer eventuellt att vidarebefordras
                            till formuläret inom kort.
                        </Typography>
                    </Paper>
                );

            case "OK":
                return (
                    <Paper elevation={3} className={classes.paper}>
                        <img src={logo} alt="Tillotts Logo" className={classes.logo} />
                        {!downloaded ? (
                            <>
                                <Typography variant="h4" className={classes.header}>
                                    Nedladdning av material
                                </Typography>
                                <Typography variant="body1" style={{ textAlign: "center", marginTop: 16 }}>
                                    Nedladdningen startar automatiskt. Om det inte fungerar
                                    visar vi en knapp om 15 sekunder.
                                </Typography>

                                {showFallbackButton && (
                                    <Box mt={2} display="flex" justifyContent="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.downloadButton}
                                            onClick={handleManualDownload}
                                            disabled={isDownloading}
                                            style={{ minWidth: 140 }}
                                        >
                                            {isDownloading ? (
                                                <>
                                                    <CircularProgress size={20} style={{ color: "white", marginRight: 8 }} />
                                                    Laddar...
                                                </>
                                            ) : (
                                                "Ladda ned manuellt"
                                            )}
                                        </Button>
                                    </Box>
                                )}

                                <hr style={{ margin: "2rem 0", width: "50%", opacity: 0.5 }} />
                            </>
                        ) : (
                            <>
                                <Typography variant="h4" className={classes.header}>
                                    Materialet är nedladdat!
                                </Typography>
                                <Typography variant="body1" style={{ marginTop: "1rem" }}>
                                    Tack för att du laddade ner materialet.
                                </Typography>
                            </>
                        )}

                        <Alert severity="info" style={{ marginTop: 16 }}>
                            <Typography variant="body1">
                                <strong>Observera:</strong>
                            </Typography>
                            <ul style={{ marginTop: 0 }}>
                                <li>Materialet är endast avsett för “read-only”</li>
                                <li>Du får inte ändra innehållet eller ordningsföljden på bilderna</li>
                                <li>
                                    Tillotts Pharma kan inte ta ansvar för eventuellt ändrat innehåll eller förstörd
                                    funktionalitet.
                                </li>
                            </ul>
                        </Alert>

                        <Typography variant="body2" align="center" style={{ marginTop: 16 }}>
                            Om du har frågor efter att ha laddat ner filen, vänligen kontakta{" "}
                            <Link href="mailto:nordicinfo@tillotts.com" className={classes.link}>
                                nordicinfo@tillotts.com
                            </Link>
                        </Typography>
                    </Paper>
                );
        }
    };

    return (
        <Container component="main" className={classes.container}>
            <CssBaseline />
            {renderContentByStatus()}
            <Backdrop className={classes.backdrop} open={isDownloading}>
                <CircularProgress color="inherit" />
                <Typography variant="h6" style={{ marginTop: 16 }}>
                    Filen laddas ned, vänligen vänta...
                </Typography>
            </Backdrop>
        </Container>
    );
};