import React, { useEffect, useState } from "react";
import {
    Container,
    CssBaseline,
    Grid,
    Typography,
    Paper,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    Button,
    Link, CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/tillotts_logo.svg";
import {TranslatedText} from "../lang";
import {PrivacyPolicy} from "./privacyPolicy";
import {MoreInfo} from "./moreInfo";
import {PrivacyPolicySweden} from "./privacyPolicySweden";
const {REACT_APP_ENDPOINT} = process.env;
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        minHeight: "100vh", // Vertikal centrering
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    paper: {
        width: "100vw",
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "none", // Ingen box-shadow i mobilvy
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(4),
            boxShadow: "none", // Ingen box-shadow i mobilvy
            minWidth: "auto",
        },
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(3),
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0),
        },
    },
    header: {
        color: 'rgb(1, 172, 230)',
        fontWeight: "bold",
        textAlign: "center",
    },
    logo: {
        width: "150px",
        marginBottom: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(1.5),
        backgroundColor: "#D51317",
        borderRadius: "30px",
        maxWidth: "200px",
        fontWeight: 600,
        "&:hover": {
            backgroundColor: "#A10E12", // Mörkare röd vid hover
        },
        "&:disabled": {
            backgroundColor: "lightgray", // För inaktiverad knapp
        },
    },
    mandatoryText: {
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
    },
    checkboxLabel: {
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
        lineHeight: 1.5,
    },
    checkbox: {
        "&.Mui-checked": {
            color: "rgb(219, 30, 36)", // Ändra färg när markerad
        },
    },
    successMessage: {
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    link: {
        color: "#D51317",
        fontWeight: "bold",
    },
    TillottsFont: {
        fontFamily: 'Verdana-Regular, sans-serif!important'
    }
}));
document.title = "IBD Utbildningsmaterial";
export const IBDConsentForm = () => {
    const classes = useStyles();
    const [translatedText, setTranslatedText] = React.useState(TranslatedText(1));
    const [formState, setFormState] = useState({
        firstname: "",
        lastname: "",
        site: "",
        specialty: "",
        specialty_other: "",
        email_primary: "",
        email_second: "",
        phone: "",
        mobile: "",
        consent: false,
        tos: false,
        read_only: false,
    });
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [emailPrimaryError, setEmailPrimaryError] = useState(false);
    const [loading, setLoading] = useState(false); // Nytt state
    const [submitted, setSubmitted] = useState(false); // Nytt state för att visa tack-text
    const [sessionId, setSessionId] = React.useState('');
    useEffect(() => {
        const {
            firstname,
            lastname,
            site,
            specialty,
            email_primary,
            consent,
            tos,
            read_only,
        } = formState;

        const isSpecialtyValid =
            specialty &&
            (specialty !== "other" || (specialty === "other" && formState.specialty_other));

        setDisableSubmit(
            !(
                firstname &&
                lastname &&
                site &&
                isSpecialtyValid &&
                email_primary &&
                consent &&
                tos &&
                read_only &&
                !emailPrimaryError
            )
        );
    }, [formState, emailPrimaryError]);

    useEffect(() => {
        try {
            fetch(`${REACT_APP_ENDPOINT}session`).then(response => response.json()).then(data => setSessionId(data.sessionId));
        } catch (error: any) {
            console.error("Failed to fetch session ID:", error);
        }

    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });

        if (name === "email_primary") {
            setEmailPrimaryError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormState({ ...formState, [name]: checked });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true); // Starta laddning
            await fetch(`${REACT_APP_ENDPOINT}ibd/consent`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    payload: {
                        firstname: formState.firstname,
                        lastname: formState.lastname,
                        primaryEmail: formState.email_primary,
                        specialty: formState.specialty === 'other' ? formState.specialty_other : formState.specialty,
                        site: formState.site,
                        country: 1,
                        consent: 'Approved',
                        origin: window.location.origin
                    },
                    sessionId,
                    action: 'SUBMIT_IBD_CONSENT'
                })
            });
            setSubmitted(true);
        } catch (error) {
            console.error("Form submission failed:", error);
        } finally {
            setLoading(false); // Laddning slutar oavsett framgång eller fel
        }
    };

    // Om formuläret är skickat, visa tack-texten
    if (submitted) {
        return (
            <Container component="main" className={classes.container}>
                <CssBaseline />
                <Paper elevation={3} className={classes.paper}>
                    {
                        /*
                    }
                        <img src={logo} alt="Tillotts Logo" className={classes.logo} />
                    {
                        */
                    }

                        <Typography component="h1" variant="h4" className={classes.header}>
                            Tack för din registrering!
                        </Typography>
                        <Typography variant="body1" className={classes.successMessage}>
                            Dina uppgifter har skickats in och inom kort får du ett e-postmeddelande med en bekräftelse och en länk till materialet.
                        </Typography>
                        <Typography variant="body2" className={classes.successMessage}>
                            Har du några frågor? Vänligen kontakta oss på{" "}
                            <Link href="mailto:nordicinfo@tillotts.com" className={classes.link}>
                                nordicinfo@tillotts.com
                            </Link>.
                        </Typography>
                    </Paper>
                </Container>
            );
        }


        return (
            <Container component="main" maxWidth="xs" className={classes.container}>
                <CssBaseline />
                <Paper elevation={3} className={classes.paper}>
                    {
                        /*
                    }
                    <img src={logo} alt="Tillotts Logo" className={classes.logo} />
                    {
                        */
                }
                <Typography component="h1" variant="h5" className={classes.header}>
                    IBD utbildningsmaterial
                </Typography>
                <Typography variant="body2" align="center">
                    För att ladda ner utbildningsmaterialet, vänligen fyll i formuläret
                    nedan.
                </Typography>
                <form className={classes.form}>
                    <Grid container spacing={2}>
                        {/* Förnamn och Efternamn */}
                        <Grid item xs={12}>
                            <TextField
                                name="firstname"
                                label="Förnamn"
                                required
                                fullWidth
                                onChange={handleInputChange}
                                value={formState.firstname}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="lastname"
                                label="Efternamn"
                                required
                                fullWidth
                                onChange={handleInputChange}
                                value={formState.lastname}
                            />
                        </Grid>
                        {/* Arbetsplats */}
                        <Grid item xs={12}>
                            <TextField
                                name="site"
                                label="Arbetsplats"
                                required
                                fullWidth
                                onChange={handleInputChange}
                                value={formState.site}
                            />
                        </Grid>
                        {/* Specialitet */}
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel>Roll</InputLabel>
                                <Select
                                    name="specialty"
                                    value={formState.specialty}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem key={'Läkare'} value={'Läkare'}>
                                        Läkare
                                    </MenuItem>
                                    <MenuItem key={'Sjuksköterska'} value={'Sjuksköterska'}>
                                        Sjuksköterska
                                    </MenuItem>
                                    <MenuItem key={'other'} value={'other'}>
                                        Annan
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {formState.specialty === "other" && (
                            <Grid item xs={12}>
                                <TextField
                                    name="specialty_other"
                                    label="Annan roll"
                                    fullWidth
                                    onChange={handleInputChange}
                                    value={formState.specialty_other}
                                />
                            </Grid>
                        )}
                        {/* E-post */}
                        <Grid item xs={12}>
                            <TextField
                                name="email_primary"
                                label="E-postadress"
                                required
                                fullWidth
                                onChange={handleInputChange}
                                value={formState.email_primary}
                                error={emailPrimaryError}
                                helperText={emailPrimaryError && "Ogiltig e-postadress"}
                            />
                            <span className={classes.mandatoryText}>
                                {translatedText.required_text}
                            </span>
                        </Grid>
                        {/* Checkboxar */}
                        <Grid item xs={12}>
                        <FormControlLabel
                                style={{alignItems: 'flex-start'}}
                                control={
                                    <Checkbox
                                        name="consent"
                                        classes={{ root: classes.checkbox }}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={<Typography component={'div'} className={classes.checkboxLabel}>{translatedText.consent} <MoreInfo country={1}></MoreInfo></Typography>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="tos"
                                        classes={{ root: classes.checkbox }}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={
                                    <Typography className={classes.checkboxLabel}>
                                        {translatedText.privacy_policy}
                                        <PrivacyPolicySweden/>
                                    </Typography>
                            }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{alignItems: 'flex-start'}}
                                control={
                                    <Checkbox
                                        name="read_only"
                                        classes={{ root: classes.checkbox }}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={
                                    <Typography className={classes.checkboxLabel}>Jag förstår och bekräftar att när jag laddat ner materialet behåller jag det i ”read-only” läge. Ändringar i presentationen förstör meny-funktionerna samt navigering, Tillotts Pharma kan då ej ansvara för funktionalitet och/eller innehåll.</Typography>
                            }
                            />
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            {/* Submit-knapp */}
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleSubmit}
                                disabled={disableSubmit}
                            >
                                {loading ? (
                                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={20} style={{ color: "white" }} /> {/* Laddningsindikator */}
        </span>
                                ) : (
                                    "Skicka in"
                                )}
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </Container>
    );
};
