import React, { useState, useEffect } from "react";
import {
    Container,
    CssBaseline,
    Paper,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Box,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const { REACT_APP_ENDPOINT } = process.env;

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    header: {
        color: "rgb(1, 172, 230)",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    paper: {
        width: "100%",
        maxWidth: 600,
        minWidth: 600,
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(),
            boxShadow: "none",
            minWidth: "auto",
        },
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    button: {
        color: "white",
        marginTop: theme.spacing(2),
        backgroundColor: "#D51317",
        borderRadius: "30px",
        fontWeight: 600,
        "&:hover": {
            backgroundColor: "#A10E12",
        },
    },
    errorMessage: {
        color: "red",
        marginTop: theme.spacing(2),
    },
    successMessage: {
        color: "green",
        marginTop: theme.spacing(2),
    },
}));

const UploadPage: React.FC = () => {
    const [linkSent, setLinkSent] = useState(false);
    const { userToken } = useParams<{ userToken: string }>();
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState<string | null>(userToken);
    const [uploading, setUploading] = useState(false); // Hanterar uppladdningsstatus
    const [fileUploaded, setFileUploaded] = useState(false); // Kontroll för uppladdad fil
    const [message, setMessage] = useState<string | null>(null);
    const [messageType, setMessageType] = useState<"error" | "success" | null>(null);
    const [isVerified, setIsVerified] = useState(false);
    useEffect(() => {
        if (token) {
            verifyToken(token);
        }
    }, []);

    const requestLink = async () => {
        setLoading(true);
        setMessage(null);
        try {

            const response = await fetch(`${REACT_APP_ENDPOINT}ibd/upload`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    payload: { email },
                    action: 'IBD_REQUEST_UPLOAD_LINK',
                }),
            });
            if (response.ok) {
                setMessage("En länk har skickats till din e-post!");
                setMessageType("success");
                setLinkSent(true);
            } else {
                setMessage("Fel: Kunde inte skicka e-post. Kontrollera e-postadressen.");
                setMessageType("error");
            }
        } catch (error) {
            console.error(error);
            setMessage("Ett tekniskt fel uppstod. Försök igen senare.");
            setMessageType("error");
        } finally {
            setLoading(false);
        }
    };

    const verifyToken = async (token: string) => {
        setLoading(true);
        setMessage(null);
        try {
            const response = await fetch(`${REACT_APP_ENDPOINT}/ibd/verify-token/${token}`);
            const data = await response.json();
            if (!response.ok || data.status !== 'OK') {
                setMessage("Token ogiltig eller utgången. Begär en ny länk.");
                setMessageType("error");
                return;
            }
            setIsVerified(true);
        } catch (error) {
            console.error(error);
            setMessage("Ett tekniskt fel uppstod vid verifiering. Försök igen senare.");
            setMessageType("error");
        } finally {
            setLoading(false);
        }
    };

    const handleFileUpload = async (files: File[]) => {
        if (files.length === 0) return;
        const file = files[0];

        if (file.type !== "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
            setMessage("Endast PPTX-filer tillåts.");
            setMessageType("error");
            return;
        }
        setUploading(true);
        setMessage(null);
        try {
            const response = await fetch(`${REACT_APP_ENDPOINT}/ibd/upload/${token}`,{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({type: file.type, filename: file.name}),
            });
            const data = await response.json();
            if (response.ok && data?.status !== 'USED') {
                const signedUrl = data.url;
                const response = await fetch(signedUrl, {
                    method: "PUT",
                    headers: { "Content-Type": file.type },
                    body: file,
                });
                if (response.ok) {
                    setMessage("Filen har laddats upp framgångsrikt!");
                    setMessageType("success");
                    setFileUploaded(true); // Markera som uppladdad
                } else {
                    setMessage("Fel vid uppladdning. Försök igen.");
                    setMessageType("error");
                }
            } else {
                setMessage("Token ogiltig eller utgången. Begär en ny länk123.");
                setMessageType("error");
            }
        } catch (error) {
            console.error(error);
            setMessage("Ett tekniskt fel uppstod vid uppladdning. Försök igen senare.");
            setMessageType("error");
        } finally {
            setUploading(false);
        }
    };

    const renderMessage = () => {
        if (!message) return null;
        const messageClass =
            messageType === "success" ? classes.successMessage : classes.errorMessage;

        return <Typography className={messageClass}>{message}</Typography>;
    };

    return (
        <Container className={classes.container}>
            <CssBaseline />
            <Paper className={classes.paper}>
                {!token ? (
                    <>
                        <Typography variant="h5" className={classes.header}>
                            Begär en uppladdningslänk för IBD-material
                        </Typography>
                        <Typography variant="body1" style={{ textAlign: "center", marginBottom: "16px" }}>
                            Ange din e-postadress för att få en uppladdningslänk för att ladda upp IBD-presentationen.
                            Kontrollera att e-postadressen
                            är korrekt innan du skickar din begäran.
                        </Typography>
                        <TextField
                            className={classes.textField}
                            label="E-postadress"
                            fullWidth
                            disabled={linkSent}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            disabled={loading || linkSent}
                            onClick={requestLink}
                        >
                            {loading ? <CircularProgress size={24} /> : "Skicka länk"}
                        </Button>
                        <Typography
                            variant="body2"
                            style={{ color: "#888888", marginTop: 8, textAlign: "center" }}
                        >
                            Endast godkända e-postadresser kan begära en uppladdningslänk. Om din e-postadress inte fungerar, kontakta <a href="mailto:joakim.sannheim@tillotts.com" style={{ color: "rgb(1, 172, 230)", textDecoration: "none" }}>joakim.sannheim@tillotts.com</a> för hjälp.
                        </Typography>
                        {renderMessage()}
                    </>
                ) : isVerified ? (
                    <>
                        <Typography variant="h5" className={classes.header}>
                            Ladda upp IBD-material
                        </Typography>
                        <Typography variant="body1" style={{ textAlign: "center", marginBottom: "16px" }}>
                            Vänligen ladda upp presentationen (IBD Presentationen). Materialet måste vara i PPTX-format och. Säkerställ att filen är korrekt
                            och uppdaterad innan du laddar upp.
                        </Typography>
                        <Box>
                            <input
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                onChange={(e) => {
                                    if (e.target.files && e.target.files[0]) {
                                        handleFileUpload([e.target.files[0]]);
                                    }
                                }}
                                style={{ display: "none" }}
                                id="file-upload"
                            />
                            <label htmlFor="file-upload">
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    component="span"
                                    disabled={uploading || fileUploaded} // Inaktivera när uppladdning pågår eller är klar
                                >
                                    {uploading ? (
                                        <>
                                            <CircularProgress size={20} style={{ color: "white", marginRight: 8 }} />
                                            Laddar upp...
                                        </>
                                    ) : fileUploaded ? (
                                        "Uppladdad"
                                    ) : (
                                        "Ladda upp IBD-material"
                                    )}
                                </Button>
                            </label>
                        </Box>
                        {renderMessage()}
                    </>
                ) : loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Typography variant="h5" className={classes.header}>
                            Verifiering misslyckades
                        </Typography>
                        <Typography variant="body1" className={classes.errorMessage}>
                            {message || "Token är ogiltig eller utgången. Begär en ny länk."}
                        </Typography>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => {setToken(null); setMessage(null)}}
                        >
                            Tillbaka
                        </Button>
                    </>
                )}
            </Paper>
        </Container>
    );
};

export default UploadPage;